<template>
  <div class="watchlist" :dir="[localDisplayLang === 'ara' ? 'rtl' : 'ltr']">
    <div v-if="isLoading">
      <div v-for="(item, index) in 2" :key="index">
        <categoriesPlaceholder></categoriesPlaceholder>
      </div>
    </div>
    <LoadingComponent  v-if="isLoaderEnabled" :size="'50px'"></LoadingComponent>
    <div>
      <div
        v-if="finalPlaylistMovies.length === 0 &&
            !isLoading">
        <p class="empty-message first-message">{{ mylistEmpty }}</p>
        <p class="empty-message">{{ mylistEmptySubTitle }}</p>

      </div>

      <!-- <div
        :key="finalPlaylistMovies.length"
        class="contents"
        v-if="finalPlaylistMovies.length > 0 && !isLoading"
      >
        <div class="container" style="position:relative">
          <h3 class="heading-watchlist">{{ $t("MOVIES") }}</h3>

          <div class="watchlist-grids">
            <watchlistGridCard
              :contents="finalPlaylistMovies"
              @removedWatchlistContent="checkWatchlistContent"
            ></watchlistGridCard>
          </div>
        </div>
      </div> -->

      <div class="container" style="position:relative">
          <div class="watchlist-grids">
            <watchlistGridCard
              :contents="finalPlaylistMovies"
              @removedWatchlistContent="checkWatchlistContent"
            ></watchlistGridCard>
          </div>
        </div>

      
<!-- 
      <div
        :key="finalPlaylistSeries.length"
        class="contents"
        v-if="finalPlaylistSeries.length > 0 && !isLoading"
      >
        <div class="container" style="position:relative">
          <h3 class="heading-watchlist">{{ $t("SERIES") }}</h3>

          <div class="watchlist-grids">
            <watchlistGridCard
              :contents="finalPlaylistSeries"
              @removedWatchlistContent="checkWatchlistContent"
            ></watchlistGridCard>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/database";
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";

export default {
  props: {
    menuDetail: {
      type: Object,
    },
  },
  data() {
    return {
      menuTitle: null,
      watchContents: false,

      watchlistContent: [],
      categorizedMovieContent: [],
      categorisedTvshow: [],
      categories: [],
      filteredContents: [],
      playlist: [],
      menuDetailItem: null,
      localDisplayLang: null,
      mylistEmpty: "",
      mylistEmptySubTitle: "",
      detailPlaylist: [],
      finalPlaylist: [],
      totalContents: [],

      finalPlaylistSeries: false,
      finalPlaylistMovies: false,

      firebaseInstance: null,

      moviesReload: false,
      seriesReload: false,

      isLoading: false,
      noContentsInWatchlist: false,
      isLoaderEnabled:false,
      mixedWatchlist: []
    };
  },
  watch: {
    localDisplayLang(val) {
      if (val) {
        this.mylistEmpty = this.$t("Your watchlist is Empty");
        this.mylistEmptySubTitle = this.$t(
          "Add your favorite content that you want to watch later"
        );
      }
    },
    detailPlaylist(val) {
      if (val.length === this.playlist.length) {
        this.finalPlaylist = val.map((item, i) =>
          Object.assign({}, item, this.playlist[i])
        );
        this.finalPlaylist = this.finalPlaylist.sort((a, b) => {
          return b.updatedat - a.updatedat;
        });
        console.log("finalPlaylist--------->", this.finalPlaylist);
        this.watchContents = true;
      }
    },
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "profileid"]),

    locale() {
      console.log("CHANGE IN LOCALE");
      return this.$i18n.locale;
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.mylistEmpty = this.$t("Your watchlist is Empty");
    this.mylistEmptySubTitle = this.$t(
      "Add your favorite content that you want to watch later"
    );

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;

      this.menuTitle = this.localDisplayLang === "ara" ? "نوربلاي" : "My List";

      console.log("Language changed", data);

      this.checkPlaylist();
    });

    this.menuTitle = this.localDisplayLang === "ara" ? "نوربلاي" : "My List";

    // eventBus.$on(`contents-for-watchlist`, (response) => {
    //   // console.log("content detail response", response);
    //   this.detailPlaylist.push(response);
    //   // console.log("detailPlaylist-------->", this.detailPlaylist);
    // });
  },

  mounted() {
    this.checkPlaylist();

    eventBus.$on("on-watchlist-state-change", () => {
      console.log("EVENT WATCHLIST FIRED");
      this.checkPlaylist();
    });
  },

  methods: {
    ...mapActions(["listBundleItem", "listContent"]),

    checkPlaylist(isDelete=false) {
      if (isDelete) {
        this.isLoaderEnabled = true
      } else {
        this.isLoading = true;
      }
      
      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

      this.firebaseInstance = firebase.database().ref(path);

      this.firebaseInstance.once("value").then((snapshot) => {
        let nodeContentArr = this.collectionsToArray(snapshot.val());

        let totalContents = [];

        nodeContentArr.forEach((element, index) => {
          if (element.inwatchlist) {
            totalContents.push(element);
          }
        });
        if (totalContents.length === 0) {
          this.finalPlaylistMovies = [];
          this.finalPlaylistSeries = [];

          this.isLoading = false;
          this.isLoaderEnabled = false;
          return;
        }

        this.totalContents = totalContents;

        if (this.totalContents && this.totalContents.length > 0) {
          // this.makeTheWatchlistAPIcall();
          this.makeTheWatchlistAPIcallClub();
        } else {
          this.noContentsInWatchlist = true;
          this.isLoading = false;
          this.isLoaderEnabled = false
        }
      });
    },
    makeTheWatchlistAPIcallClub() {
      let ObjectIdArr = [];

      this.totalContents.forEach((element, index) => {
        ObjectIdArr.push(`"${element.objectid}"`)
      });

      if (ObjectIdArr && ObjectIdArr.length > 0) {
  
        let payload = {
          contentlist: "[" + `${ObjectIdArr}` + "]",
          displaylanguage: this.localDisplayLang,
        };

        this.listContent(payload).then((data) => {
          this.finalPlaylistMovies = data.data.data ? data.data.data : [];

          this.isLoaderEnabled = false
          if (this.isLoading) {
            this.isLoading = false;
          }

        })

      }


    },
    makeTheWatchlistAPIcall() {
      let seriesObjectIdArr = [];
      let moviesObjectIdArr = [];

      this.totalContents.forEach((element, index) => {
        if (element.category === "MOVIE") {
          moviesObjectIdArr.push(`"${element.objectid}"`);
        } else if (element.category === "TVSHOW") {
          seriesObjectIdArr.push(`"${element.objectid}"`);
        }
      });

      if (moviesObjectIdArr && moviesObjectIdArr.length > 0) {
  
        let payload = {
          objecttype: "CONTENT",
          contentlist: "[" + `${moviesObjectIdArr}` + "]",
          displaylanguage: this.localDisplayLang,
        };

        this.listContent(payload).then((data) => {
          this.finalPlaylistMovies = data.data.data;

          console.log("MOVIES API RESPONSE", this.finalPlaylistMovies);

          if (seriesObjectIdArr && seriesObjectIdArr.length > 0) {
        let payloadSeries = {
          contentlist: "[" + `${seriesObjectIdArr}` + "]",
          displaylanguage: this.localDisplayLang,
        };

        this.listContent(payloadSeries).then((data) => {
          this.finalPlaylistSeries = data.data.data;

          if (!this.finalPlaylistSeries) {
            this.finalPlaylistSeries = [];
          }


          this.isLoaderEnabled = false
          if (this.isLoading) {
            this.isLoading = false;
          }
        });
      } else {
        this.finalPlaylistSeries = [];
      }

      
          this.isLoaderEnabled = false
          if (this.isLoading) {
            this.isLoading = false;
          }
        });
      } else {
        this.finalPlaylistMovies = [];
      }

 

      this.isLoading = false;

    },

    showEmptyMessage() {
      // this.watchContents = true;
      setTimeout(() => {
        this.setTimeout = false;
        this.watchlistContent = null;
      }, 4000);
    },

    checkWatchlistContent(value) {
      if (value) {
        console.log("THE VALUE", value);
        this.checkPlaylist(true);
      }
    },

    initialRoute() {
      this.$router.push({ name: "Home" });
    },
  },

  beforeDestroy() {
    console.log("DESTROY HOOK CALLED");

    eventBus.$off("on-watchlist-state-change");
    eventBus.$off("check-local-storage");

    this.firebaseInstance.off("value", (data) => {
      console.log("THE FIREBASE DATABASE EVENT IS OFF", data);
    });
  },

  components: {
    categoriesPlaceholder: () =>
      import(
        /* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"
      ),
    watchlistGridCard: () =>
      import(/* webpackChunkName: "itemListTemp" */ "./watchlistGridCards.vue"),
    LoadingComponent: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),

  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./watchlist.scss"
</style>
